import React, { Component } from 'react';
import { buildUrl } from 'react-instafeed'
 import Image from './Image'

// 🔥️ These are in your code (not this repo)
// import useAbortableFetch from '@hooks/useAbortableFetch'
// import Image from '@components/Image'




class GalleryInsta extends Component {
    render() {

        // const options = {
        //     accessToken: 'access...',
        //     clientId: 'client...',
        //     get: 'user', // popular, user
        //     locationId: null,
        //     resolution: 'standard_resolution', // thumbnail, low_resolution, standard_resolution
        //     sortBy: 'none', // none, least-commented, least-liked, least-recent, most-commented, most-liked, most-recent, random
        //     tagName: null,
        //     userId: 123,
        //   }

        // const { json, loading, error, abort } = useAbortableFetch(buildUrl(options))
        // if (loading) return 'Loading...'
        // if (error) return `Error: ${error}`
        // if (!json) return null
        
        // const { data, meta, pagination } = json
        


        return (
            <div className="GalleryInsta py-5" >
                <div className="container" >
                    <div className="row" >
                        
                        {/* {
                            data &&
                            data.map(({ caption, id, images, tags }, index) => {
                                const image = images[options.resolution]
                                return (
                                    <Image
                                    key={index}
                                    url={image.url}
                                    title={caption.text}
                                    caption={caption.text}
                                    width={image.width}
                                    height={image.height}
                                    />
                                )
                            })} */}
                    </div>
                </div>
            </div>
        )
    }
}


export default GalleryInsta