
import { baseUrl } from '../../urls/urls'
import axios from 'axios'

export const galleryApi = () => dispatch => {
    axios.get(baseUrl+`asset-by-category?asset_category=carousel `)
    .then( galleryData => {
        dispatch({
            type: 'GALLER_DATA',
            payload: galleryData
        })
    })
    .catch(err => {
        dispatch({
            type: 'GALLER_DATA_ERR', 
            payload: err
        })
        if(err.response) {
        }
    })
} 

