import React from 'react'
import { connect } from 'react-redux'
import en from './en.json'
import ar from './ar.json'
import about_1 from '../../../src/img/about_img/rsz_img_1602_copy-min.png'
import about_2 from '../../../src/img/about_img/IMG_1774-minJPG.jpg'
import about_3 from '../../../src/img/about_img/IMG_1818-minJPG.jpg'
import about_4 from '../../../src/img/about_img/IMG_2832-minJPG.jpg'
import about_5 from '../../../src/img/about_img/IMG_2079-minJPG.jpg'
import about_6 from '../../../src/img/about_img/rsz_img_1581-min.jpg'
import about_7 from '../../../src/img/about_img/rsz_pexels-photo-302899.jpg'
import ar_flag from '../../../src/img/about_img/flag-round-250.png'
import en_flag from '../../../src/img/about_img/Flag_of_the_United_States.svg'
import bg_rounded from "../../../src/img/bg-rounded.svg";
import './owanAFreanchise.scss'
import Line from "../../img/Line.svg";

export const AboutFranchise = ({ lang, setLang }) => {


    let fullContainerDiv = 'stepone pt-3 pt-md-5  pt-md-0';
    let dashHeaderImg = 'h3 mb-3 pt-md-5 mt-md-5'
    if (!lang) {
        fullContainerDiv += ' text-right'
        dashHeaderImg += ' d-flex justify-content-end'
    }

    return (
        <>
            <a onClick={setLang} className="btn-warning quick-lang-fix"> {lang ? 'العربية' : 'English'} {lang ? <img src={ar_flag} width="20" alt="" /> : <img src={en_flag} width="20" alt="" />}</a>
            <div className={fullContainerDiv} >


                <div className="row">

                    <div className="col-12 col-md-7 d-flex flex-column justify-content-center">
                        <h1 className={dashHeaderImg}>
                            <img src={Line} className="img-fluid mx-3 order-1" alt="img" />
                            {lang ? en.about.about_1 : ar.about.about_1}{" "}
                        </h1>

                        <ul dir={lang ? "LTR" : "RTL"}>
                            <li>{lang ? en.about.about_2 : ar.about.about_2}{" "}
                            </li>
                            <li>{lang ? en.about.about_3 : ar.about.about_3}{" "}
                            </li>
                            <li>{lang ? en.about.about_4 : ar.about.about_4}{" "}
                            </li>
                            <li>{lang ? en.about.about_5 : ar.about.about_5}{" "}</li>
                        </ul>
                    </div>

                    <div className="col-12 col-md-5">
                        <iframe width="420" height="345" src="https://www.youtube.com/embed/WO2YNpchpCA" frameBorder="0"
                            allowFullScreen="allowfullscreen">
                        </iframe>
                    </div>

                </div>

                <div className="pt-md-5">
                    <div className=" mt-md-3 pt-md-5 ">
                        <div className="row">
                            <div className="col-md-5 offset-md-2 mt-3 d-block d-md-none">
                                <h1 className={dashHeaderImg}>
                                    <img src={Line} className="img-fluid mx-3 order-1" alt="img" />
                                    {lang ? en.about.about_6 : ar.about.about_6}{" "}{" "}
                                </h1>

                                <ul dir={lang ? "LTR" : "RTL"}>
                                    <li>{lang ? en.about.about_7 : ar.about.about_7}{" "}
                                    </li>
                                </ul>
                            </div>

                            <div className="col-md-5">
                                <iframe width="420" height="345" src="https://www.youtube.com/embed/wymhGaoBxZo"
                                    frameBorder="0" allowFullScreen="allowfullscreen">
                                </iframe>
                            </div>
                            <div className="col-md-5 offset-md-2 mt-3 d-none d-md-block">
                                <h1 className={dashHeaderImg}>
                                    <img src={Line} className="img-fluid mx-3 order-1" alt="img" />
                                    {lang ? en.about.about_6 : ar.about.about_6}{" "}
                                </h1>

                                <ul dir={lang ? "LTR" : "RTL"}>
                                    <li>{lang ? en.about.about_7 : ar.about.about_7}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row mt-5 position-relative">
                    <div className="col-12 col-md-7 d-flex flex-column justify-content-center">
                        <h1 className={dashHeaderImg}>
                            <img src={Line} className="img-fluid mx-3 order-1" alt="img" />
                            {lang ? en.about.about_8 : ar.about.about_8}{" "}
                        </h1>

                        <ul dir={lang ? "LTR" : "RTL"}>
                            <li>{lang ? en.about.about_9 : ar.about.about_9}
                            </li>
                        </ul>

                        <div className="bg-rounded">
                            <img src={bg_rounded} className="img-fluid" alt="img" />
                        </div>
                    </div>
                    <div className="col-12 col-md-5">
                        <img src={about_1} alt="" className="img-fluid rounded-circle" />
                    </div>
                </div>


                <div className="pt-md-5">
                    <div className=" mt-md-3 pt-md-5 ">
                        <div className="row">
                            <div className="col-md-5 offset-md-2 mt-3 d-block d-md-none">
                                <h1 className={dashHeaderImg}>
                                    <img src={Line} className="img-fluid mx-3 order-1" alt="img" />
                                    {lang ? en.about.about_10 : ar.about.about_10}{" "}
                                </h1>

                                <ul dir={lang ? "LTR" : "RTL"}>
                                    <li>{lang ? en.about.about_11 : ar.about.about_11}
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-5">
                                <img src={about_2} className="img-fluid rounded-circle" alt="" />
                            </div>
                            <div className="col-md-5 offset-md-2 mt-3 d-none d-md-block">
                                <h1 className={dashHeaderImg}>
                                    <img src={Line} className="img-fluid mx-3 order-1" alt="img" />
                                    {lang ? en.about.about_10 : ar.about.about_10}{" "}
                                </h1>

                                <ul dir={lang ? "LTR" : "RTL"}>
                                    <li>{lang ? en.about.about_11 : ar.about.about_11}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-5">

                    <div className="col-12 col-md-7 d-flex flex-column justify-content-center">
                        <h1 className={dashHeaderImg}>
                            <img src={Line} className="img-fluid mx-3 order-1" alt="img" />
                            {lang ? en.about.about_12 : ar.about.about_12}{" "}
                        </h1>

                        <ul dir={lang ? "LTR" : "RTL"}>
                            <li>{lang ? en.about.about_13 : ar.about.about_13}
                            </li>
                        </ul>
                    </div>

                    <div className="col-12 col-md-5">
                        <img src={about_3} alt="" className="img-fluid rounded-circle" />
                    </div>
                </div>


                <div className="pt-md-5">
                    <div className=" mt-md-3 pt-md-5 ">
                        <div className="row">
                            <div className="col-md-5 offset-md-2 mt-3 d-block d-md-none">
                                <h1 className={dashHeaderImg}>
                                    <img src={Line} className="img-fluid mx-3 order-1" alt="img" />
                                    {lang ? en.about.about_14 : ar.about.about_14}{" "}
                                </h1>


                                <ul dir={lang ? "LTR" : "RTL"}>
                                    <li>{lang ? en.about.about_15 : ar.about.about_15}
                                    </li>
                                    <li>{lang ? en.about.about_16 : ar.about.about_16}
                                    </li>
                                </ul>

                                {/* <div className="bg-rounded right-one">
                                    <img src={bg_rounded} className="img-fluid" alt="img" />
                                </div> */}
                            </div>

                            <div className="col-md-5">
                                <img src={about_4} alt="" className="img-fluid rounded-circle" />
                            </div>

                            <div className="col-md-5 offset-md-2 mt-3 d-none d-md-block">
                                <h1 className={dashHeaderImg}>
                                    <img src={Line} className="img-fluid mx-3 order-1" alt="img" />
                                    {lang ? en.about.about_14 : ar.about.about_14}{" "}
                                </h1>

                                <ul dir={lang ? "LTR" : "RTL"}>
                                    <li>{lang ? en.about.about_15 : ar.about.about_15}
                                    </li>
                                    <li>{lang ? en.about.about_16 : ar.about.about_16}
                                    </li>
                                </ul>

                                <div className="bg-rounded right-one">
                                    <img src={bg_rounded} className="img-fluid" alt="img" />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>


                <div className="row mt-5">
                    <div className="col-12 col-md-7 d-flex flex-column justify-content-center">
                        <h1 className={dashHeaderImg}>
                            <img src={Line} className="img-fluid mx-3 order-1" alt="img" />
                            {lang ? en.about.about_17 : ar.about.about_17}{" "}
                        </h1>

                        <ul dir={lang ? "LTR" : "RTL"}>
                            <li>{lang ? en.about.about_18 : ar.about.about_18}
                            </li>
                            <li>{lang ? en.about.about_19 : ar.about.about_19}
                            </li>
                        </ul>
                    </div>

                    <div className="col-12 col-md-5">
                        <img src={about_5} alt="" className="img-fluid rounded-circle" />
                    </div>

                </div>


                <div className="pt-md-5">
                    <div className=" mt-md-3 pt-md-5 ">
                        <div className="row">
                            <div className="col-md-5 offset-md-2 mt-3 d-block d-md-none">
                                <h1 className={dashHeaderImg}>
                                    <img src={Line} className="img-fluid mx-3 order-1" alt="img" />
                                    {lang ? en.about.about_20 : ar.about.about_20}{" "}
                                </h1>

                                <ul dir={lang ? "LTR" : "RTL"}>
                                    <li>{lang ? en.about.about_21 : ar.about.about_21}
                                    </li>
                                    <li>{lang ? en.about.about_22 : ar.about.about_22}
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-5">
                                <img src={about_6} alt="" className="img-fluid rounded-circle" />
                            </div>

                            <div className="col-md-5 offset-md-2 mt-3 d-none d-md-block">
                                <h1 className={dashHeaderImg}>
                                    <img src={Line} className="img-fluid mx-3 order-1" alt="img" />
                                    {lang ? en.about.about_20 : ar.about.about_20}{" "}
                                </h1>

                                <ul dir={lang ? "LTR" : "RTL"}>
                                    <li>{lang ? en.about.about_21 : ar.about.about_21}
                                    </li>
                                    <li>{lang ? en.about.about_22 : ar.about.about_22}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row mt-5">

                    <div className="col-12 col-md-7 d-flex flex-column justify-content-center">
                        <h1 className={dashHeaderImg}>
                            <img src={Line} className="img-fluid mx-3 order-1" alt="img" />
                            {lang ? en.about.about_23 : ar.about.about_23}{" "}
                        </h1>

                        <ul dir={lang ? "LTR" : "RTL"}>
                            <li>{lang ? en.about.about_24 : ar.about.about_24}
                            </li>
                            <li>{lang ? en.about.about_25 : ar.about.about_25}
                            </li>
                            <li>{lang ? en.about.about_26 : ar.about.about_26}
                            </li>
                        </ul>
                    </div>

                    <div className="col-12 col-md-5">
                        <img src={about_7} alt="" className="img-fluid rounded-circle" />
                    </div>


                </div>
            </div>
        </>
    )
}

function mapStateToProps(state) {
    return { lang: state.lang }
}

function mapDispatchToProps(dispatch) {
    return {
        setLang: () => dispatch({ type: 'TOGGLE_LANG' }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AboutFranchise);
