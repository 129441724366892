const initState = {
  category: [],
  category_by_id: [],
  products: [],
  sliderInfo: [],
  video: [],
  gallery: [],
  contact_Us_res: null,
  franchise_res: null,
  country: [],
  lang: true
};

const reducer = (state = initState, action) => {
  switch (action.type) {

    case "PRODUCT_CATEGORY":

      return {
        ...state,
        category: action.payload
      };

    case "TOGGLE_LANG":

      return {
        ...state,
        lang: !state.lang
      };

    case "PRODUCT_CATEGORY_BY_ID":

      return {
        ...state,
        category_by_id: action.payload
      };

    case "PRODUCT_SUCCESS":

      return {
        ...state,
        products: action.payload.data
      };

    case "CONTACT_US":

      return {
        ...state,
        contact_Us_res: action.payload
      };

    case "FRANCHISE_SUCCESS":

      return {
        ...state,
        franchise_res: action.payload
      };

    case "SLIDER_SUCSSESS":

      return {
        ...state,
        sliderInfo: action.payload
      };

    case "VIDEO_SUCSSESS":

      return {
        ...state,
        video: action.payload
      };

    case "GALLER_DATA":

      return {
        ...state,
        gallery: action.payload.data
      };

    case "COUNTRY_DATA":

      return {
        ...state,
        country: action.payload.data
      };


    default:
      return state;
  }
};


export default reducer;
