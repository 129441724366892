import React from "react";
import { imageUrl } from '../../../urls/urls';
import placeholder from "../../../img/placeholder.jpg";
import { Link } from "react-router-dom";

const DepartmentSummary = ({ data, handleClick }) => {
  return (
    <div className="col-md-2 col-6">
      <Link to={"/category/"+data.id}>
        <div
          className="product-item mb-4"
          onClick={e => handleClick(e.target.id)}
          id={data.id}
        >
          {data.image ? (
            <img
              src={imageUrl+data.image}
              className="img-fluid w-100"
              alt="img"
              onClick={e => handleClick(e.target.id)}
              id={data.id}
            />
          ) : (
            <img
              src={placeholder}
              className="img-fluid"
              onClick={e => handleClick(e.target.id)}
              id={data.id}
              alt="img"
            />
          )}
          <div
            className="heading-product bg-warning p-3"
            onClick={e => handleClick(e.target.id)}
          >
            <h4
              className="h6 text-center text-dark"
              onClick={e => handleClick(e.target.id)}
            >
              {" "}
              {data ? data.name : null}{" "}
            </h4>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default DepartmentSummary;
