import { baseUrl } from '../../urls/urls'
import axios from 'axios'

export const contactUs = (data) => dispatch => {
    axios.post(baseUrl+'contact', {
        "name": data.name,
        "email": data.email,
        "subject": data.subject,
        "message": data.message
    })
    .then(res => {
        dispatch({
            type : 'CONTACT_US',
            payload : res,
        })
    })
    .catch( err => {
        dispatch({
            type : 'CONTACT_US_ERROR',
            payload : err.response.data
        })
        if (err.response) {

        }
  })
}