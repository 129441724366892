import React, { Component } from "react";
import { product } from "../../store/actions/product";
import { connect } from "react-redux";
import "./products.scss";
import ProductsCategry from "./ProductsCategry";
import ProductSummiry from "./ProductSummiry";

class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: props.products,
      active: 1,
      loaded: false
    }
  }

  componentDidMount = () => {
    const id = this.props.match.params.id;
    this.props.product()
    if (this.props.products.length > 0) {
      this.setState({ active: id ? id : this.props.products[0].id, categories: this.props.products.map(p => p.products) })
    }
  };

  componentWillReceiveProps(newProps) {
    const id = this.props.match.params.id;
    if (newProps.products.length > 0 && this.props.products != newProps.products) {
      this.setState({ active: id ? id : newProps.products[0].id, categories: newProps.products.map(p => p.products) })
    }
  }

  render() {
    const { products } = this.props;

    setTimeout(() => {
      this.setState({ loaded: false })
    }, 5000)

    return (
      <div className=" products py-5 ">
        <div className="container">
          <div className="row">
            <div className="col-md-6 text-center mx-auto">
              <h1 className="h3 border-heading d-inline-block"> Products </h1>
              <small className="text-muted d-block">
                {" "}
                The best products that make you happy{" "}
              </small>
            </div>
            <div className="col-md-12 mt-3">
              <ul
                class="nav nav-pills mb-3 border p-3 rounded "
                id="pills-tab"
                role="tablist"
              >
                {products ? products.map(prod => {
                  return (
                    <li class="nav-item">
                      <a
                        class={`nav-link text-dark ${this.state.active == prod.id ? 'active' : null}`}

                        role="tab"
                        aria-controls="pills-home"
                        aria-selected={this.state.active == prod.id ? "true" : "false"}
                        onClick={() => this.setState({ active: prod.id, loaded: true })}
                      >
                        {prod.name}
                        {/* {this.state.categories ? this.state.categories.find(cat2 => cat == cat2.id).name : null} */}
                      </a>
                    </li>
                  )
                }) : null}
              </ul>
            </div>
            <div className="col-md-12">
              <div class="tab-content mt-5" id="pills-tabContent">

                <div
                  class="tab-pane fade show active"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  <div className="row">
                    {!this.state.loaded ? products
                      ? products.filter(products => products.id == this.state.active).map(productItems => {
                        return <ProductSummiry products={productItems.products} />;
                      })
                      : null : <div class="lds-dual-ring"></div>}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    products: state.products
  };
};

export default connect(mapStateToProps, { product })(Products);
