import { baseUrl } from '../../urls/urls.js'
import axios from 'axios'

export const product = () => dispatch => {
    axios.get(baseUrl + 'get-products')
        .then(product => {
            dispatch({
                type: 'PRODUCT_SUCCESS',
                payload: product,
            })
        })
        .catch(err => {
            dispatch({
                type: 'PRODUCT_ERR',
                payload: err
            })
            if (err.response) {

            }
        })
}
