import { baseUrl } from "../../urls/urls.js";
import axios from "axios";

export const ApiFranchise = data => dispatch => {
  let bodyFormData = new FormData();
  bodyFormData.append(
    "personal_info",
    JSON.stringify({
      age: data.age,
      city: data.city,
      surname: "null",
      firstname: data.full_name,
      work_number: data.work_number,
      mobile_number: data.mobile_number,
      landline_number: data.landline_number,
      investment_capital: data.capital
    })
  );
  bodyFormData.append(
    "marital_status",
    JSON.stringify({
      status: "single",
      children: "No",
      ages_of_children: data.ages_of_children
    })
  );
  bodyFormData.append(
    "area_of_interset",
    JSON.stringify({
      country: data.country_area,
      city: data.city_area,
      is_store_avilable: "yes",
      store_status: "owned"
    })
  );
  bodyFormData.append(
    "store_availability",
    JSON.stringify({
      address: data.address,
      city: data.city_store,
      indoor_area: data.indoor_area,
      exterior_area: data.exterior_area,
      basement_loft: data.basement_loft,
      facade_length: data.facade_length
    })
  );

  for (var pair of bodyFormData.entries()) {
  }

  axios({
    method: "post",
    url: baseUrl + "franchise",
    data: bodyFormData
  })
    .then(OFranchise => {
        window.fbq('track', 'CompleteRegistration');
      dispatch({
        type: "FRANCHISE_SUCCESS",
        payload: OFranchise
      });
    })
    .catch(err => {
      dispatch({
        type: "FRANCHISE_ERROR",
        payload: err.response
      });
      if (err.response) {
      }
    });
};
