import { baseUrl } from '../../urls/urls'
import axios from 'axios'

export const slider = () => dispatch => {
    axios.get(baseUrl+`asset-by-category?asset_category=slider`)
    .then( sliderData => {
        dispatch({
            type: 'SLIDER_SUCSSESS',
            payload: sliderData.data
        })
    })
    .catch(err => {
        dispatch({
            type: 'SLIDER_ERR', 
            payload: err
        })
        if(err.response) {
        }
    })
} 

