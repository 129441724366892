import React, { Component } from "react";
import "./department.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Line from "../../../img/Line.svg";
import { Redirect } from 'react-router-dom'
import DepartmentSummary from "./DepartmentSummary";
import { connect } from "react-redux";
import { department_id } from "../../../store/actions/department";

class Department extends Component {

  state = {
    RedirectPage: null
  };

 
  handleClick = clicked_id => {
    this.props.department_id(clicked_id);
    this.setState({ RedirectPage: !this.state.RedirectPage, clicked_id });
  };

  render() {
    const { category } = this.props;
    if (this.state.RedirectPage) {
      return <Redirect to={`/category/=${this.state.clicked_id}`} />;
    }

    return (
      <div className="department">
        <div className="container mt-md-3 pt-md-5 ">
          <div className="row">

            <div className="col-md-10 mx-auto text-center">
              <h1 className="h3 my-3">
                <img src={Line} className="img-fluid mr-3" alt="img" />
                Great products
                <Link
                  to="/category"
                  className="mx-2 btn btn-warning "
                >
                  All products
                  <FontAwesomeIcon icon={faArrowRight} className="mt-1 " />
                </Link>
              </h1>
              <p className="text-muted">
              We are glad to offer you our products that have an excellent taste that makes you feel happy </p>
            </div>

            <div className="col-md-12 mt-md-4 mt-5">
              <div className="row">
                {category
                  ? category.map(category => {
                      return (
                        <DepartmentSummary
                          id={category.id}
                          key={category.id}
                          handleClick={this.handleClick}
                          data={category}
                        />
                      );
                    })
                  : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    category: state.category
  };
};

const mapDispatchToProps = dispatch => {
  return {
    department_id: data => dispatch(department_id(data))
  };
};


export default connect(mapStateToProps, mapDispatchToProps )(Department);
