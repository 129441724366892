import React, { Component } from "react";
import validator from 'validator';
import twitter from "../../img/twitter.png";
import instagram from "../../img/links/instagram.png";
import facebook from "../../img/links/facebook.png";
import youtube from "../../img/links/youtube.png";
import contact_us from "../../img/contact_us.svg";
import { Button, Alert } from "react-bootstrap";
import {faPaperPlane, faPhoneSquare} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { contactUs } from '../../store/actions/contactUs'
import { connect } from 'react-redux'
import Swal from 'sweetalert2'
import {FaMailBulk} from "react-icons/fa";
// import withReactContent from 'sweetalert2-react-content'

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  onOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})



class ContactUs extends Component {

  state = {
    name: '',
    email: '',
    subject: '',
    message: ''
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  handlesubmit = (e) => {
    e.preventDefault();
    const { name, email, message, subject } = this.state
    if (!name) {
      Toast.fire({
        icon: 'error',
        title: 'Please Enter Name'
      })
    }
    else if (!validator.isEmail(email)) {
      Toast.fire({
        icon: 'error',
        title: 'Please Enter Email '
      })
    }
    else if (!subject) {
      Toast.fire({
        icon: 'error',
        title: 'Please Enter subject '
      })
    }
    else if (!message) {
      Toast.fire({
        icon: 'error',
        title: 'Please Enter message '
      })
    } else {
      this.props.contactUs(this.state)
    }
  }

  render() {
    const { contact_Us_res } = this.props;
    if (contact_Us_res && contact_Us_res.status == 200) {
      Toast.fire({
        icon: 'success',
        title: 'Your message has been received!'
      })
      this.props.Done()
    }

    return (
      <div className="ContactUs py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <h1 className="h3 border-heading d-inline-block"> Contact Us </h1>
              <small className="text-muted d-block">
                {" "}
                We are happy to communicate with you
              </small>
            </div>
            <div className="col-md-8 d-none d-sm-block">
              <ul className="list-inline mt-4 float-right">
                <li className="list-inline-item">
                  <a href="https://www.instagram.com/wowmixtr/" target="_blank">
                    <img src={instagram} className="img-fluid" alt="img" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="https://www.facebook.com/wowmixtr/" target="_blank">
                    <img src={facebook} className="img-fluid" alt="img" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-5">
              <img src={contact_us} className="d-block img-fluid" alt="" />
              <h5 className="mt-5"> CONTACT INFORMATION </h5>
              <ul className="list-unstyled mt-3">
                <li>ADDRESS: 170 A, Istiklal CD, Beyoglu / istanbul ( In front of RichMond Hotel)</li>
                <li><a href="tel:+902122939119" className="text-dark">
                  {" "}
                  <FontAwesomeIcon
                      icon={faPhoneSquare}
                  /> +902122939119{" "}
                </a></li>
                <li>
                  <a href="mailto:info@wowmixtr.com" className="text-dark">
                    <FaMailBulk/> info@wowmixtr.com
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-6 offset-md-1">
              <form onSubmit={this.handlesubmit}>
                <div class="form-group">
                  <label htmlFor="name">Your name *</label>
                  <input
                      onChange={this.handleChange}
                      type="text"
                      class="form-control"
                      id="name"
                  />
                </div>
                <div class="form-group">
                  <label htmlFor="email">Your e-mail address *</label>
                  <input
                    onChange={this.handleChange}
                    type="email"
                    class="form-control"
                    id="email"
                  />
                </div>
                <div class="form-group">
                  <label htmlFor="subject">Subject *</label>
                  <input
                    onChange={this.handleChange}
                    type="text"
                    class="form-control"
                    id="subject"
                  />
                </div>
                <div class="form-group">
                  <label htmlFor="message">Message *</label>
                  <textarea
                    onChange={this.handleChange}
                    id="message"
                    className="form-control"
                    rows="5"
                  ></textarea>
                </div>
                <div className="row">
                  <div className="col-4 ml-auto">
                    <Button variant="warning" type="submit" className="text-left" block>
                      Send
                      <FontAwesomeIcon icon={faPaperPlane} />
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          {/* google maps */}
          <div className="row">
            <div class="mapouter col-md-12 mt-5 " id="location" >
              <div class="gmap_canvas">
                <iframe
                  className="w-100"
                  style={{ height: "300px" }}
                  id="gmap_canvas"
                  src="https://maps.google.com/maps?q=Asmal%C4%B1%20Mescit%2C%20%C4%B0stiklal%20Cd.%20168-174%2C%2034433%20Beyo%C4%9Flu%2F%C4%B0stanbul%2C%20Turkey&t=&z=17&ie=UTF8&iwloc=&output=embed"
                  frameborder="0"
                  scrolling="no"
                  marginheight="0"
                  marginwidth="0"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


const mapStateToProps = state => {
  return {
    contact_Us_res: state.contact_Us_res
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    contactUs: (data) => dispatch(contactUs(data)),
    Done: () => {
      dispatch({
        type: "CONTACT_US",
        payload: null
      });
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs)