import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import ScrollToTop from 'react-router-scroll-top'
import "./gstyle.scss";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import HomePage from "./components/homePage/HomePage";
import NavbarWowmix from "./components/layout/navbar/NavbarWowmix";
import Footer from "./components/layout/footer/Footer";
import Products from "./components/products/Products";
import AboutUs from "./components/aboutUs/AboutUs";
import ContactUs from "./components/contactUs/ContactUs";
import JoinUs from "./components/joinUs/JoinUs";
import OwanAFranchise from "./components/owanAFranchise/OwanAFranchise";
import GalleryInsta from "./components/galleryInsta/GalleryInsta";
import PageNotFound from "./components/pageNotFound/PageNotFound";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <div className="App">
          <NavbarWowmix />
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route path="/about-us" component={AboutUs} />
            {/*<Route path="/gallery" component={GalleryInsta} />*/}
            <Route path="/contact-us" component={ContactUs} />
            <Route path="/join-us" component={JoinUs} />
            <Route path='/OwnAFranchise' component={OwanAFranchise} />
            <Route path='/category/:id' component={Products} />
            <Route path='/category/' component={Products} />
            <Route component={PageNotFound} />
          </Switch>
          <Footer />
        </div>
      </ScrollToTop>

    </BrowserRouter>
  );
}

export default App;
