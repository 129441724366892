import axios from 'axios'

export const country = () => dispatch => {
    axios.get('http://country.io/names.json')
    .then( country => {
        dispatch({
            type: 'COUNTRY_DATA',
            payload: country
        })
    })
    .catch(err => {
        dispatch({
            type: 'COUNTRY_DATA_ERR', 
            payload: err
        })
        if(err.response) {
        }
    })
} 

