import React, { Component, Fragment } from "react";
import { Button } from 'react-bootstrap';
import validator from 'validator';
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { ApiFranchise } from '../../store/actions/ApiFranchise'
import Swal from 'sweetalert2'
import countryPost from '../../countryData.json'
import Email_Whatsapp from './Email_Whatsapp'


const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  onOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})


class FormFranchise extends Component {


  state = {
    full_name: "",
    // surname : "",
    city: "",
    age: "",
    country: "",
    email: "",
    landline_number: "",
    mobile_number: '',
    work_number: '',
    investment_capital: '',
    // status: "",
    // children: "",
    // ages_of_children: "",
    country_area: "",
    city_area: "",
    store_status: "",
    address: "",
    city_store: "",
    indoor_area: "",
    exterior_area: "",
    basement_loft: "",
    facade_length: "",
    is_store_avilable: false,
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { full_name, city, age, country, email, landline_number, city_area, country_area, status } = this.state
    if (!full_name) {
      Toast.fire({
        icon: 'error',
        title: 'Please Enter Full Name'
      })
    }
    else if (!city) {
      Toast.fire({
        icon: 'error',
        title: 'Please Enter City'
      })
    }
    else if (!age) {
      Toast.fire({
        icon: 'error',
        title: 'Please Enter Age'
      })
    }
    else if (!country) {
      Toast.fire({
        icon: 'error',
        title: 'Plase Enter Country'
      })
    }
    else if (!validator.isEmail(email)) {
      Toast.fire({
        icon: 'error',
        title: 'Plase enter valid email'
      })
    }
    else if (!landline_number) {
      Toast.fire({
        icon: 'error',
        title: 'Plase Enter landline number'
      })
    }
    // else if (!status) {
    //   Toast.fire({
    //     icon: 'error',
    //     title: 'Plase Enter status'
    //   })
    // }
    else if (!city_area) {
      Toast.fire({
        icon: 'error',
        title: 'Plase Enter city area'
      })
    }
    else if (!country_area) {
      Toast.fire({
        icon: 'error',
        title: 'Plase Enter country area'
      })
    } else {
      this.props.ApiFranchise(this.state)
    }
  }

  handleCheck = (e) => {
    this.setState({ is_store_avilable: e.target.checked })
  }

  handleRadio = (e) => {
    this.setState({
      status: e.target.value
    });
  }

  handleChildren = (e) => {
    this.setState({
      children: e.target.value
    });
  }

  handleStore_status = (e) => {
    this.setState({
      store_status: e.target.value
    });
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }


  render() {
    const { franchise_res } = this.props;

    if (franchise_res && franchise_res.status == 200) {
      Toast.fire({
        icon: 'success',
        title: 'Your data has been successfully received!'
      })
      this.props.Done()
    }

    return (

      <div className="FormFranchise pt-5  pt-md-0">
        <br />
        <h1 className="h3 text-center">Own A Franchise</h1>
        <p className="text-center">
          Get Opportunities to own a franchise
        </p>
        <form onSubmit={this.handleSubmit}>
          <h6 className="h3 border-heading ">
            {" "}
            Personal Information{" "}
          </h6>
          <div className="row">
            <div className="col-md-4">
              <div className="form-group ">
                <label htmlFor="full_name">Full name *</label>
                <input
                  onChange={this.handleChange}
                  type="text"
                  className="form-control"
                  id="full_name"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group ">
                <label htmlFor="city">City *</label>
                <input
                  onChange={this.handleChange}
                  type="text"
                  className="form-control"
                  id="city"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group ">
                <label htmlFor="age">Age *</label>
                <input
                  onChange={this.handleChange}
                  type="number"
                  className="form-control"
                  id="age"
                />
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group ">
                <label htmlFor="country">Country *</label>
                <select className="form-control" id="country" onChange={this.handleChange} >
                  {countryPost.map(item => {
                    return <option value={item.name}> {item.name} </option>
                  })}
                </select>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group ">
                <label htmlFor="email">E-mail *</label>
                <input
                  onChange={this.handleChange}
                  type="text"
                  className="form-control"
                  id="email"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group ">
                <label htmlFor="landline_number">Landline phone number *</label>
                <input
                  onChange={this.handleChange}
                  type="number"
                  className="form-control"
                  id="landline_number"
                />
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group ">
                <label htmlFor="mobile_number">Mobile phone number </label>
                <input
                  onChange={this.handleChange}
                  type="number"
                  className="form-control"
                  id="mobile_number"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group ">
                <label htmlFor="work_number">Work phone number </label>
                <input
                  onChange={this.handleChange}
                  type="number"
                  className="form-control"
                  id="work_number"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group ">
                <label htmlFor="investment_capital">
                  Available Investment Capital (USD)
                </label>
                <input
                  onChange={this.handleChange}
                  type="text"
                  className="form-control"
                  id="investment_capital"
                />
              </div>
            </div>
          </div>

          {/* Marital Status */}
          {/* <h6 className="h3 border-heading d-inline-block mt-5 mb-4">
            {" "}
            Marital Status{" "}
          </h6>
          <div className="row">
            <div className="col-md-12 mb-3">
              <div className="desc d-inline-block mr-5">Pick your status *</div>
              <div className="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  id="status_single"
                  name="status"
                  className="custom-control-input"
                  value="Single"
                  checked={this.state.status === "Single"}
                  onChange={this.handleRadio}
                />
                <label className="custom-control-label" htmlFor="status_single">
                  Single
                </label>
              </div>
              <div className="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  id="status_married"
                  name="status"
                  className="custom-control-input"
                  value="Married"
                  checked={this.state.status === "Married"}
                  onChange={this.handleRadio}
                />
                <label className="custom-control-label" htmlFor="status_married">
                  Married
                </label>
              </div>
            </div>

            {this.state.status == "Married" ?
              <Fragment>
                <div className="col-md-12">
                  <div className="d-inline-block mr-5">
                    Do you have any children ?
                </div>
                  <div className="custom-control custom-radio custom-control-inline">
                    <input
                      type="radio"
                      id="customRadio1"
                      name="customRadio"
                      className="custom-control-input"
                      value="Yes"
                      checked={this.state.children === "Yes"}
                      onChange={this.handleChildren}
                    />
                    <label className="custom-control-label" htmlFor="customRadio1">
                      Yes
                  </label>
                  </div>
                  <div className="custom-control custom-radio custom-control-inline">
                    <input
                      type="radio"
                      id="customRadio2"
                      name="customRadio"
                      className="custom-control-input"
                      value="No"
                      checked={this.state.children === "No"}
                      onChange={this.handleChildren}
                    />
                    <label className="custom-control-label" htmlFor="customRadio2">
                      No
                  </label>
                  </div>
                </div>

                {this.state.children == "Yes" ?

                  <div className="col-md-5">
                    <div className="form-group row mt-3">
                      <label htmlFor="ages_of_children" className="col-sm-4 col-form-label">
                        Ages of children
                </label>
                      <div className="col-sm-8">
                        <input
                          onChange={this.handleChange}
                          type="text"
                          className="form-control"
                          id="ages_of_children"
                        />
                      </div>
                    </div>
                  </div>

                  : null}



              </Fragment>
              : null}



          </div> */}

          <h6 className="h3 border-heading d-inline-block mt-5 mb-4">
            {" "}
            Area/as of interest{" "}
          </h6>
          <div className="row">
            <div className="col-md-4">
              <div className="form-group ">
                <label htmlFor="country_area">Country of interest *</label>
                {/* <input
                  onChange={this.handleChange}
                  type="text"
                  className="form-control"
                  id="country_area"
                /> */}
                <select className="form-control" id="country_area" onChange={this.handleChange} >
                  {countryPost.map(item => {
                    return <option value={item.name}> {item.name} </option>
                  })}
                </select>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group ">
                <label htmlFor="city_area">City *</label>
                <input
                  onChange={this.handleChange}
                  type="text"
                  className="form-control"
                  id="city_area"
                />
              </div>
            </div>

            <div className="col-md-12">
              <div className="custom-control custom-checkbox my-1 mr-sm-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  className="custom-control-input"
                  checked={this.state.is_store_avilable}
                  onChange={this.handleCheck}
                  id="check"
                />
                <label className="custom-control-label" htmlFor="check">
                  Do you have a store that is available? *
                </label>
              </div>
            </div>

            <div className="col-md-12 mt-2">
              <div className="d-inline-block mr-5">
                Your store is owned or leased?
              </div>
              <div className="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  id="customRadio5"
                  name="customRadio"
                  className="custom-control-input"
                  value="Owned"
                  checked={this.state.store_status === "Owned"}
                  onChange={this.handleStore_status}
                />
                <label className="custom-control-label" htmlFor="customRadio5">
                  Owned
                </label>
              </div>
              <div className="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  id="customRadio7"
                  name="customRadio"
                  className="custom-control-input"
                  value="Leased"
                  checked={this.state.store_status === "Leased"}
                  onChange={this.handleStore_status}
                />
                <label className="custom-control-label" htmlFor="customRadio7">
                  Leased
                </label>
              </div>
            </div>
          </div>

          <h6 className="h3 border-heading d-inline-block mt-5 mb-4">
            {" "}
            Store availability{" "}
          </h6>
          <div className="row">
            <div className="col-md-4">
              <div className="form-group ">
                <label htmlFor="address">Address</label>
                <input
                  onChange={this.handleChange}
                  type="text"
                  className="form-control"
                  id="address"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group ">
                <label htmlFor="city_store">City</label>
                <input
                  onChange={this.handleChange}
                  type="text"
                  className="form-control"
                  id="city_store"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group ">
                <label htmlFor="indoor_area">
                  Indoor area <sup> em2 </sup>{" "}
                </label>
                <input
                  onChange={this.handleChange}
                  type="text"
                  className="form-control"
                  id="indoor_area"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group ">
                <label htmlFor="exterior_area">
                  Exterior area <sup> em2 </sup>{" "}
                </label>
                <input
                  onChange={this.handleChange}
                  type="text"
                  className="form-control"
                  id="exterior_area"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group ">
                <label htmlFor="basement_loft">
                  Basement/Loft <sup> em2 </sup>{" "}
                </label>
                <input
                  onChange={this.handleChange}
                  type="text"
                  className="form-control"
                  id="basement_loft"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group ">
                <label htmlFor="facade_length">
                  Facade length <sup> em2 </sup>{" "}
                </label>
                <input
                  onChange={this.handleChange}
                  type="text"
                  className="form-control"
                  id="facade_length"
                />
              </div>
            </div>
          </div>

          <div className="custom-control custom-checkbox my-1 mr-sm-2">
            <input
              type="checkbox"
              checked={this.state.checkItem}
              onChange={this.handleCheck}
              className="custom-control-input"
              id="customControlInline"
            />
            <label className="custom-control-label" htmlFor="customControlInline">
              You agree to the terms and conditions ?
            </label>
          </div>

          <div className="row">
            <div className="col-md-10">
              {franchise_res ? <h6 className="mt-4" > Received the data we will contact you as soon as possible  <FontAwesomeIcon icon={faPaperPlane} /> </h6> : null}
            </div>
            <div className="col-md-2 mt-4 ml-auto">
              <Button variant="warning" className="text-left" type="submit" block>
                Send
                <FontAwesomeIcon icon={faPaperPlane} />
              </Button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    franchise_res: state.franchise_res,
    countryData: state.country

  }
}

const mapDispatchToProps = dispatch => {
  return {
    ApiFranchise: (data) => dispatch(ApiFranchise(data)),
    Done: () => {
      dispatch({
        type: "FRANCHISE_SUCCESS",
        payload: null
      });
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormFranchise)
