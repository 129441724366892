import React, { Component } from "react";
import axios from "axios";
import validator from 'validator';
import contact_us from "../../img/contact_us.svg";
import { Button } from "react-bootstrap";
import {faPaperPlane, faPhoneSquare} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from 'sweetalert2'
import {FaMailBulk} from "react-icons/fa";

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  onOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

const baseUrl = process.env.REACT_APP_URL;

const initialState = {
  first_name: '',
  last_name: '',
  mobile: '',
  email: '',
  job_id: '',
  experience: '',
  age: '',
  first_name_error: '',
  last_name_error: '',
  mobile_error: '',
  email_error: '',
  job_id_error: '',
  experience_error: '',
  age_error: '',
}

export default class JoinUs extends Component {
  // state
  state = {
    jobs: [],
    ...initialState
  }

  async componentDidMount() {
    try {
      const response = await axios.get(`${baseUrl}/api/landing/jobs`);
      this.setState({ jobs: response.data });
    } catch (error) {
      // error feedback
      Toast.fire({
        icon: 'error',
        title: error.message
      })
    }
  }

  // handle input value change
  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  toastError = (title) => Toast.fire({ icon: 'error', title });

  onSubmit = async (e) => {
    // prevent default behavior
    e.preventDefault();

    //  form values
    const {
      first_name,
      last_name,
      mobile,
      email,
      job_id,
      experience,
      age
    } = this.state;

    if (!first_name) return this.toastError('Please Enter First Name');

    if (!last_name) return this.toastError('Please Enter Last Name');

    if (!mobile.match(/^5(0[5-7]|[3-5]\d)\d{3}\d{4}$/)) return this.toastError('Please Enter Valid Mobile Number');

    if (!validator.isEmail(email)) return this.toastError('Please Enter Valid Email');

    if (!job_id) return this.toastError('Please Choose Job');

    if (!experience) return this.toastError('Please Choose Experience Level');

    if (!age) return this.toastError('Please Enter Age');

    try {
      await axios.post(
        `${baseUrl}/api/landing/job-requests`, 
        { first_name, last_name, mobile, email, job_id, experience, age }
      );
      // reset form
      this.setState({ jobs: this.state.jobs, ...initialState });
      // first toast
      Toast.fire({ icon: 'success', title: 'Your request has been received!'});
    } catch (error) {
      // toast error message
      this.toastError(error.message);
      // update state with errors
      const errors = error.response.data.errors;
      for (const errorInput in errors) {
        this.setState({ [`${errorInput}_error`]: errors[errorInput] });
      }
    }
  }

  render() {
    const { 
      jobs, 
      first_name_error, 
      last_name_error, 
      email_error, 
      mobile_error, 
      age_error, 
      job_id_error,
      experience_error 
    } = this.state;

    return (
      <div className="ContactUs py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <h1 className="h3 border-heading d-inline-block"> Join Us </h1>
              <small className="text-muted d-block">
                We are happy to communicate with you
              </small>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-5">
              <img src={contact_us} className="d-block img-fluid" alt="" />
              <h5 className="mt-5"> CONTACT INFORMATION </h5>
              <ul className="list-unstyled mt-3">
                <li>ADDRESS: 170 A, Istiklal CD, Beyoglu / istanbul ( In front of RichMond Hotel)</li>
                <li><a href="tel:+902122939119" className="text-dark">
                  {" "}
                  <FontAwesomeIcon
                      icon={faPhoneSquare}
                  /> +902122939119{" "}
                </a></li>
                <li>
                  <a href="mailto:info@wowmixtr.com" className="text-dark">
                    <FaMailBulk/> info@wowmixtr.com
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-6 offset-md-1">
              <form onSubmit={this.onSubmit}>
                <div className="form-group">
                <label htmlFor="first_name">Adı *</label>
                  <input
                    onChange={this.handleChange}
                    type="text"
                    className="form-control"
                    id="first_name"
                    required
                  />
                  {first_name_error && first_name_error.map(errorMessage => <p className="red-text">{errorMessage}</p>)}
                </div>
                <div className="form-group">
                  <label htmlFor="last_name">Soy adı *</label>
                  <input
                    onChange={this.handleChange}
                    type="text"
                    className="form-control"
                    id="last_name"
                    required
                  />
                  {last_name_error && last_name_error.map(errorMessage => <p className="red-text">{errorMessage}</p>)}
                </div>
                <div className="form-group">
                  <label htmlFor="mobile">Telefon *</label>
                  <input
                    onChange={this.handleChange}
                    onBlur={this.onUnFocus}
                    type="text"
                    className="form-control"
                    id="mobile"
                    required
                  />
                  {mobile_error && mobile_error.map(errorMessage => <p className="red-text">{errorMessage}</p>)}
                </div>
                <div className="form-group">
                  <label htmlFor="email">E-Mail *</label>
                  <input
                    onChange={this.handleChange}
                    type="email"
                    className="form-control"
                    id="email"
                    required
                  />
                  {email_error && email_error.map(errorMessage => <p className="red-text">{errorMessage}</p>)}
                </div>
                <div className="form-group">
                  <label htmlFor="job_id">Vazife *</label>
                  <select className="form-control" id="job_id" onChange={this.handleChange} required>
                    <option value=""></option>
                    {jobs && jobs.map(item => {
                      return <option key={item.id} value={item.id}> {item.name} </option>
                    })}
                  </select>
                  {job_id_error && job_id_error.map(errorMessage => <p className="red-text">{errorMessage}</p>)}
                </div>
                <div className="form-group">
                  <label htmlFor="experience">Deneyim *</label>
                  <select className="form-control" id="experience" onChange={this.handleChange} required>
                    <option value=""></option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="+5">+5</option>
                  </select>
                  {experience_error && experience_error.map(errorMessage => <p className="red-text">{errorMessage}</p>)}
                </div>
                <div className="form-group">
                  <label htmlFor="age">Yaş *</label>
                  <input
                    onChange={this.handleChange}
                    type="number"
                    min={1}
                    className="form-control"
                    id="age"
                    required
                  />
                  {age_error && age_error.map(errorMessage => <p className="red-text">{errorMessage}</p>)}
                </div>
                <div className="row">
                  <div className="col-4 ml-auto">
                    <Button variant="warning" type="submit" className="text-left" block>
                      Submit
                      <FontAwesomeIcon icon={faPaperPlane} />
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}