import React, { useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AboutFranchise from './AboutFranchise'
import AboutFranchiseTwo from './AboutFranchiseTwo'
import FormFranchise from './FormFranchise'
import StepLabel from '@material-ui/core/StepLabel';
import { MdInfoOutline, MdModeEdit, MdPermDeviceInformation } from "react-icons/md";
import './owanAFreanchise.scss'

const useStyles = makeStyles(theme => ({
    root: {
        width: '90%',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    completed: {
        display: 'inline-block',
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

function getSteps() {
    return ['About', 'Information', 'Application'];
}

function getStepContent(step) {
    switch (step) {
        case 0:
            return <AboutFranchise />;
        case 1:
            return <AboutFranchiseTwo />;
        case 2:
            return <FormFranchise />;
        default:
            return 'Unknown step';
    }
}

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

export default function WizardForm() {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});
    const steps = getSteps();

    const myRef = useRef(null)
    const executeScroll = () => scrollToRef(myRef)

    function totalSteps() {
        return steps.length;
    }

    function completedSteps() {
        return Object.keys(completed).length;
    }

    function isLastStep() {
        return activeStep === totalSteps() - 1;
    }

    function allStepsCompleted() {
        return completedSteps() === totalSteps();
    }

    function handleNext() {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                // find the first step that has been completed
                steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
    }

    function handleBack() {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    }

    const handleStep = step => () => {
        setActiveStep(step);
    };

    function handleComplete() {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        handleNext();
    }

    function handleReset() {
        setActiveStep(0);
        setCompleted({});
    }

    useEffect(() => {
        var urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get('step') === 'about') {
            setActiveStep(0);
        } else if (urlParams.get('step') === 'info') {
            setActiveStep(1);
        } else if (urlParams.get('step') === 'form') {
            setActiveStep(2);
        }
    }, [])

    const icons = [MdInfoOutline, MdModeEdit, MdPermDeviceInformation, MdPermDeviceInformation]

    return (

        <>

            <div className="top-steps-btn-wizard-form">
                <div>
                    <Button disabled={activeStep === 0} onClick={handleBack} className="btn-warning">Back</Button>
                    <Button variant="contained" onClick={() => { handleNext(); executeScroll() }} className="btn-warning ml-2">
                        Next</Button>
                </div>
            </div>



            <div className={classes.root} ref={myRef}>
                <Stepper nonLinear alternativeLabel activeStep={activeStep}>
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <StepButton onClick={handleStep(index)} completed={completed[index]}>
                                <StepLabel StepIconComponent={icons[index]}>{label}</StepLabel>
                            </StepButton>
                        </Step>
                    ))}
                </Stepper>
                <div>
                    {allStepsCompleted() ? (
                        <div>
                            <Typography className={classes.instructions}>All steps completed - you&apos;re finished</Typography>
                            <Button onClick={handleReset}>Reset</Button>
                        </div>
                    ) : (
                            <div>
                                <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
                                <div className="d-flex justify-content-center">
                                    <Button disabled={activeStep === 0} onClick={handleBack} className="btn-warning">Back</Button>
                                    <Button variant="contained" onClick={() => { handleNext(); executeScroll() }} className="btn-warning ml-2">
                                        Next</Button>
                                </div>
                            </div>
                        )}
                </div>
            </div>
        </>
    );
}
