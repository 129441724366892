import React, { Component, Fragment } from "react";
import Swiper from "react-id-swiper";
import wowmix_18 from "../../../img/wowmix_18.jpg";
import { connect } from "react-redux";
import { slider } from "../../../store/actions/slider";
import { imageUrl } from "../../../urls/urls";

class Slider extends Component {
  componentDidMount = () => {
    this.props.slider();
  };

  render() {
    const { sliderInfo } = this.props;
    const params = {
      effect: 'coverflow',
      grabCursor: true,
      centeredSlides: true,
      slidesPerView: 'auto',
      coverflowEffect: {
        rotate: 50,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: true
      },
      pagination: {
        el: '.swiper-pagination'
      }
    }
    return (
      <Fragment>
        {sliderInfo.length>0 ?
          <Swiper {...params} activeSlideKey='1'>
              {sliderInfo.map((slide, index ) => {
                  return (
                        <div className={`slider-header ${index == 0 ? 'swiper-slide-active' : '' }
                          // ${index == 1 ? 'perv' : '' }
                          // ${index == 2 ? 'swiper-slide-next' : '' }
                        `} key={index}>
                          <img
                            src={imageUrl+slide.image}
                            className="img-fluid d-block mx-auto "
                            alt="img"
                          />
                        </div>
                  );
                })
              }
          </Swiper>  : null}

      </Fragment>

    );
  }
}

const mapStateToProp = state => {
  return {
    sliderInfo: state.sliderInfo
  };
};

export default connect(mapStateToProp, { slider })(Slider);
