import React, { Component } from "react";
import "./franchise.scss";
import mug from '../../../img/mug.svg'
import dish from '../../../img/dish.svg'
import tea_herbal from '../../../img/tea_herbal.svg'
import yoghurt from '../../../img/yoghurt.svg'
import coworking from '../../../img/coworking.svg'
import bg_path_right from '../../../img/bg-path-right.svg'
import { Link } from 'react-router-dom';



export default class Franchise extends Component {
  render() {
    return (
      <div className="franchise mb-5 pb-5 ">
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-8">
              <h1 className="h3 border-top pt-2 "> Own A Franchise WOWMIX </h1>
            </div>
            <div className="col-md-8 offset-md-1">
              <p className="mt-3">
                {" "}
                Take the first step towards owning and operating a successful franchise with Wowmix
              </p>
            </div>
          </div>
          <div className="franchise-main-item mt-5 pt-md-5">
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                 {/* item */}
                  <div className="col-6">
                    <div className="franchise-item mb-5">
                        <img src={yoghurt} className="img-fluid mb-3"  alt="img"/>
                        <h6> Frozen Yogurt </h6>
                        <p> 
                            Imported from Greece <br />
                            Fresh Prepared every 3 hours <br />
                            Natural 100% <br />
                            Healthy 100% <br />
                        </p>
                    </div>
                  </div>
                    {/* item */}
                       {/* item */}
                  <div className="col-6">
                    <div className="franchise-item mb-5">
                        <img src={dish} className="img-fluid mb-3"  alt="img"/>
                        <h6> Waffle </h6>
                        <p> We serve 3 kinds of waffle (Standard, Double, Cup)  </p>
                    </div>
                  </div>
                    {/* item */}

                    {/* item */}
                  <div className="col-6">
                    <div className="franchise-item mb-5">
                        <img src={mug} className="img-fluid mb-3"  alt="img"/>
                        <h6>Coffee Corner </h6>
                        <p> We have a coffee corner (Cold &#38; Hot) </p>
                    </div>
                  </div>
                    {/* item */}
                 
                   
                    {/* item */}
                  <div className="col-6">
                    <div className="franchise-item mb-5">
                        <img src={tea_herbal} className="img-fluid mb-3"  alt="img"/>
                        <h6> Tea corner </h6>
                        <p> We have 12 kind of selected herbal tea  </p>
                    </div>
                  </div>
                    {/* item */}
                </div>
              </div>
              <div className="col-md-4 offset-md-2">
                  <div className="get-franchise text-center border rounded p-5 mt-3 bg-white">
                      <img src={coworking} className="img-fluid"  alt="img"/>
                      <Link
                        to="/contact-us"
                        className="btn btn-warning text-center d-block mt-5 "
                      >
                        Own A Franchise
                      </Link>
                      {/* <button className="btn btn-warning text-center mt-5 px-4 ">    </button> */}
                  </div>
              </div>
              <img src={bg_path_right} className="img-fluid bg-path-right" alt="img"/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
