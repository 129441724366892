import React, { useState } from 'react'
import { connect } from 'react-redux'
import en from './en.json'
import ar from './ar.json'
import Line from "../../img/Line.svg";
import ar_flag from '../../../src/img/about_img/flag-round-250.png'
import en_flag from '../../../src/img/about_img/Flag_of_the_United_States.svg'

export const AboutFranchiseTwo = ({ lang, setLang }) => {


    let fullContainerDiv = 'stepone pt-3 pt-md-5  pt-md-0';
    let dashHeaderImg = 'h3 mb-3 pt-md-5 mt-md-5'
    if (!lang) {
        fullContainerDiv += ' text-right'
        dashHeaderImg += ' d-flex justify-content-end'
    }

    return (
        <>
            <a onClick={setLang} className="btn-warning quick-lang-fix"> {lang ? 'العربية' : 'English'} {lang ? <img src={ar_flag} width="20" alt="" /> : <img src={en_flag} width="20" alt="" />}</a>
            <div className={fullContainerDiv}>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <h1 className={dashHeaderImg}>
                                <img src={Line} className="img-fluid mx-3 order-1" alt="img" />
                                {lang ? en.information.information_1 : ar.information.information_1}{" "}{" "}
                            </h1>

                            <ul dir={lang ? "LTR" : "RTL"}>
                                <li>{lang ? en.information.information_2 : ar.information.information_2}</li>
                            </ul>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <h1 className={dashHeaderImg}>
                                <img src={Line} className="img-fluid mx-3 order-1" alt="img" />
                                {lang ? en.information.information_3 : ar.information.information_3}{" "}
                            </h1>

                            <ul dir={lang ? "LTR" : "RTL"}>
                                <li>{lang ? en.information.information_4 : ar.information.information_4}</li>
                            </ul>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <h1 className={dashHeaderImg}>
                                <img src={Line} className="img-fluid mx-3 order-1" alt="img" />
                                {lang ? en.information.information_5 : ar.information.information_5} {" "}
                            </h1>

                            <ul dir={lang ? "LTR" : "RTL"}>
                                <li>{lang ? en.information.information_6 : ar.information.information_6}</li>
                            </ul>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <h1 className={dashHeaderImg}>
                                <img src={Line} className="img-fluid mx-3 order-1" alt="img" />
                                {lang ? en.information.information_7 : ar.information.information_7} {" "}
                            </h1>

                            <ul dir={lang ? "LTR" : "RTL"}>
                                <li>{lang ? en.information.information_8 : ar.information.information_8}</li>
                            </ul>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <h1 className={dashHeaderImg}>
                                <img src={Line} className="img-fluid mx-3 order-1" alt="img" />
                                {lang ? en.information.information_9 : ar.information.information_9} {" "}
                            </h1>

                            <ul dir={lang ? "LTR" : "RTL"}>
                                <li>{lang ? en.information.information_10 : ar.information.information_10}</li>
                            </ul>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <h1 className={dashHeaderImg}>
                                <img src={Line} className="img-fluid mx-3 order-1" alt="img" />
                                {lang ? en.information.information_11 : ar.information.information_11}{" "}
                            </h1>

                            <ul dir={lang ? "LTR" : "RTL"}>
                                <li>{lang ? en.information.information_12 : ar.information.information_12}</li>
                            </ul>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <h1 className={dashHeaderImg}>
                                <img src={Line} className="img-fluid mx-3 order-1" alt="img" />
                                {lang ? en.information.information_13 : ar.information.information_13} {" "}
                            </h1>

                            <ul dir={lang ? "LTR" : "RTL"}>
                                <li>{lang ? en.information.information_14 : ar.information.information_14}</li>
                                <li>{lang ? en.information.information_15 : ar.information.information_15}</li>
                            </ul>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <h1 className={dashHeaderImg}>
                                <img src={Line} className="img-fluid mx-3 order-1" alt="img" />
                                {lang ? en.information.information_16 : ar.information.information_16}{" "}
                            </h1>

                            <ul dir={lang ? "LTR" : "RTL"}>
                                <li>{lang ? en.information.information_17 : ar.information.information_17}</li>
                            </ul>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <h1 className={dashHeaderImg}>
                                <img src={Line} className="img-fluid mx-3 order-1" alt="img" />
                                {lang ? en.information.information_18 : ar.information.information_18}{" "}
                            </h1>

                            <ul dir={lang ? "LTR" : "RTL"}>
                                <li>{lang ? en.information.information_19 : ar.information.information_19} </li>
                                <li>{lang ? en.information.information_20 : ar.information.information_20}</li>
                                <li>{lang ? en.information.information_21 : ar.information.information_21}</li>
                                <li>{lang ? en.information.information_22 : ar.information.information_22}</li>
                                <li>{lang ? en.information.information_23 : ar.information.information_23}</li>
                                <li>{lang ? en.information.information_24 : ar.information.information_24}</li>
                                <li>{lang ? en.information.information_25 : ar.information.information_25}</li>
                                <li>{lang ? en.information.information_26 : ar.information.information_26}</li>
                                <li>{lang ? en.information.information_27 : ar.information.information_27}</li>
                                <li>{lang ? en.information.information_28 : ar.information.information_28}</li>
                                <li>{lang ? en.information.information_29 : ar.information.information_29}</li>
                                <li>{lang ? en.information.information_30 : ar.information.information_30}</li>
                                <li>{lang ? en.information.information_31 : ar.information.information_31}</li>
                            </ul>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <h1 className={dashHeaderImg}>
                                <img src={Line} className="img-fluid mx-3 order-1" alt="img" />
                                {lang ? en.information.information_32 : ar.information.information_32}{" "}
                            </h1>

                            <ul dir={lang ? "LTR" : "RTL"}>
                                <li>{lang ? en.information.information_33 : ar.information.information_33}</li>
                                <li>{lang ? en.information.information_34 : ar.information.information_34}
                                </li>
                                <li>{lang ? en.information.information_35 : ar.information.information_35}
                                </li>
                                <li>{lang ? en.information.information_36 : ar.information.information_36}</li>
                            </ul>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <h1 className={dashHeaderImg}>
                                <img src={Line} className="img-fluid mx-3 order-1" alt="img" />
                                {lang ? en.information.information_37 : ar.information.information_37} {" "}
                            </h1>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        </div>
                    </div>

                </div>

                <div className="row mt-5">
                    <div className="col-12">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12039.153307757331!2d28.975293!3d41.0298867!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x32a4af641680382!2sWow%20Mix!5e0!3m2!1sen!2seg!4v1592307153506!5m2!1sen!2seg" width="100%" height="450" frameBorder="0" allowFullScreen="" tabIndex="0"></iframe>
                    </div>
                </div>
            </div>

        </>
    )
}


function mapStateToProps(state) {
    return { lang: state.lang }
}

function mapDispatchToProps(dispatch) {
    return {
        setLang: () => dispatch({ type: 'TOGGLE_LANG' }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AboutFranchiseTwo);