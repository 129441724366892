import React from "react";
import placeholder from "../../img/placeholder.jpg";
import calories_img from "../../img/calories.png";


import { imageUrl } from '../../urls/urls'

const ProductSummiry = ({ products }) => {


  return (

    products.map(prod => {
      return (
        <div className="col-md-2 col-6">
          <div className="product-summiry border mb-3 ">
            <button type="button" data-toggle="modal" data-target={`#s-${prod.id}`} className="all-unset p-0">
              <img src={imageUrl + prod.image} className="img-fluid pb-0 p-3 mb-0 w-100 img-main " alt="img" />
            </button>

            <div className="modal fade" id={`s-${prod.id}`} tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <img src={imageUrl + prod.image} className="img-fluid pb-0 p-3 mb-0 w-100 model-img " alt="" />
                </div>
              </div>
            </div>

            <h6 className="px-3">{prod ? prod.name : null} </h6>
            <span className="bg-warning price  p-2">
              {" "}
              {prod ? prod.price : null} tl{" "}
            </span>
            <hr />
            <p className="px-3">
              {" "}
              <small> {prod.description} </small>{" "}
            </p>
          </div>


        </div>
      )
    })
  );
};

export default ProductSummiry;
