import { baseUrl } from '../../urls/urls.js'

export const department = () => dispatch => {
       fetch(baseUrl+'product-category',{
        method : 'GET',
    })
    .then( res => {
        if(res.ok) return res.json()
    })
    .then(category => dispatch({
        type : 'PRODUCT_CATEGORY',
        payload : category,
    }))
    .catch( (err)=>{
        dispatch({type:'PRODUCT_CATEGORY_ERR'});
    }
)}

export const department_id = (department_id) => dispatch => {
       fetch(baseUrl+`product-by-category/${department_id}`,{
        method : 'GET',
    })
    .then( res => {
        if(res.ok) return res.json()
    })
    .then(categoryById => dispatch({
        type : 'PRODUCT_CATEGORY_BY_ID',
        payload : categoryById,
    }))
    .catch( (err)=>{
        dispatch({type:'PRODUCT_CATEGORY_BY_ID_ERR'});
    }
)}

