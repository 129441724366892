import React, { Component } from "react";
import "./pageNotFound.scss";
import error from "../../img/found.gif";
import { Link } from "react-router-dom";

export default class PageNotFound extends Component {
  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <img src={error} className="img-fluid" alt="" />
          </div>
          <div className="col-md-6 mt-md-5 pt-md-5">
            <h1 className="mt-5 h3"> Page Not Found </h1>
            <p>Oops! Something is wrong.</p>
            <Link to="/" className="btn btn-warning  btn-lg px-5">
              Go back in initial page, is better.
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
