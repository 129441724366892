import React, { Component } from "react";
import "./owanAFreanchise.scss";
import instagram from "../../img/links/instagram.png";
import facebook from "../../img/links/facebook.png";
import youtube from "../../img/links/youtube.png";
import mug from '../../img/mug.svg'
import dish from '../../img/dish.svg'
import tea_herbal from '../../img/tea_herbal.svg'
import yoghurt from '../../img/yoghurt.svg'
import { connect } from "react-redux";
import { country } from '../../store/actions/country'
import WizardForm from './WizardForm'
import { FaTripadvisor } from 'react-icons/fa';
import Email_Whatsapp from "./Email_Whatsapp";


class OwanAFranchise extends Component {

  componentDidMount = () => {
    this.props.country()
  }

  render() {


    return (
      <div className="OwanAFranchise py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <h1 className="h3 border-heading d-inline-block">Own A Franchise </h1>
              <small className="text-muted d-block">
                {" "}
                Get Opportunities to own franchise
              </small>
            </div>
            <div className="col-md-8 ">
              <ul className="list-inline mt-4 float-right">
                <li className="list-inline-item">
                  <a href="https://www.instagram.com/wowmixtr/" target="_blank">
                    <img src={instagram} className="img-fluid" alt="img" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="https://www.facebook.com/wowmixtr/" target="_blank">
                    <img src={facebook} className="img-fluid" alt="img" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="https://www.youtube.com/channel/UC0fzysxajqEVv7jSrTPFInw/videos" target="_blank">
                    <img src={youtube} className="img-fluid" alt="img" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="https://ar.tripadvisor.com/Restaurant_Review-g293974-d19259352-Reviews-Wow_Mix-Istanbul.html" target="_blank" className="tripadvisor-a">
                    <FaTripadvisor className="tripadvisor-icon" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {/*<div className="parent-items">*/}
          {/*  <div className="row mt-5">*/}
          {/*    /!* item *!/*/}
          {/*    <div className="col-md-3 col-6 ">*/}
          {/*      <div className="FranschiseSummary border rounded p-4 mb-3">*/}
          {/*        <img src={yoghurt} className="img-fluid mb-3" alt="img" />*/}
          {/*        <h6 className="mt-3 mb-0 pb-0" > Frozen Yogurt </h6>*/}
          {/*        <small className="mt-0 pt-0" >*/}
          {/*          {" "}Imported from Greece <br />*/}
          {/*            Fresh Prepared every 3 hours <br />*/}
          {/*            Natural 100% <br />*/}
          {/*            Healthy 100% <br />{" "}*/}
          {/*          <div className="clipy-path"></div>*/}
          {/*        </small>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*    /!* item *!/*/}
          {/*    /!* item *!/*/}
          {/*    <div className="col-md-3 col-6 ">*/}
          {/*      <div className="FranschiseSummary border rounded p-4 mb-3">*/}
          {/*        <img src={dish} className="img-fluid mb-3" alt="img" />*/}
          {/*        <h6 className="mt-3 mb-0 pb-0" > Waffle </h6>*/}
          {/*        <small className="mt-0 pt-0" >*/}
          {/*          {" "} We serve 3 kinds of waffle (Standard, Double, Cup) {" "}*/}
          {/*          <div className="clipy-path"></div>*/}
          {/*        </small>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*    /!* item *!/*/}
          {/*    /!* item *!/*/}
          {/*    <div className="col-md-3 col-6 ">*/}
          {/*      <div className="FranschiseSummary border rounded p-4 mb-3">*/}
          {/*        <img src={mug} className="img-fluid mb-3" alt="img" />*/}
          {/*        <h6 className="mt-3 mb-0 pb-0" > Coffee Corner </h6>*/}
          {/*        <small className="mt-0 pt-0" >*/}
          {/*          We have a coffee corner (Cold &#38; Hot)*/}
          {/*        <div className="clipy-path"></div>*/}
          {/*        </small>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*    /!* item *!/*/}
          {/*    /!* item *!/*/}
          {/*    <div className="col-md-3 col-6 ">*/}
          {/*      <div className="FranschiseSummary border rounded p-4 mb-3">*/}
          {/*        <img src={tea_herbal} className="img-fluid mb-3" alt="img" />*/}
          {/*        <h6 className="mt-3 mb-0 pb-0" > Tea corner </h6>*/}
          {/*        <small className="mt-0 pt-0" >*/}
          {/*          <p> We have 12 kind of selected herbal tea  </p>*/}
          {/*          <div className="clipy-path"></div>*/}
          {/*        </small>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*    /!* item *!/*/}

          {/*  </div>*/}
          {/*</div>*/}
          <br /><br /><br />
          <WizardForm className="pt-5" />
          <br /><br /><br />
          <Email_Whatsapp />
        </div>
      </div>
    );
  }
}


const mapStateToProps = state => {
  return {
    countryData: state.country
  }
}



export default connect(mapStateToProps, { country })(OwanAFranchise)