import React, { Component } from "react";
import "./footer.scss";
import logo from "../../../img/logo_wowmax.svg";
import twitter from "../../../img/twitter.svg";
import instagram from "../../../img/instagram.svg";
import facebook from "../../../img/facebook.svg";
import youtube from "../../../img/youtube.svg";
import logo_majal from "../../../img/logo_majal.png";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkedAlt } from "@fortawesome/free-solid-svg-icons";
import { faPhoneSquare } from "@fortawesome/free-solid-svg-icons";
import {FaMailBulk} from "react-icons/fa";

export default class Footer extends Component {
  render() {
    return (
      <div className="footer mt-5">
        <div className="container">
          <div className="row border-bottom pb-4 ">
            <div className="col-md-3 col-6 border-right ">
              <div className="site-map mt-md-0 mt-5">
                <h5 className="text-warning"> Site Map </h5>
                <ul className="list-unstyled mt-3 ">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/about-us"> About Us </Link>
                  </li>
                  <li>
                    <Link to="/category"> Products </Link>
                  </li>
                  {/*<li>*/}
                  {/*  <Link to="/OwnAFranchise"> Own A Franchise </Link>*/}
                  {/*</li>*/}

                  <li>
                    <Link to="/contact-us"> Contact </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-6 d-bock d-md-none ">
              <div className="social text-center text-md-left mt-md-0 mt-5  ">
                <h5 className="text-warning"> Follow us </h5>
                <ul className="list-inline mt-4">
                  <li className="list-inline-item">
                    <Link
                      to="https://www.instagram.com/wowmixtr/"
                      target="_blank"
                    >
                      <img src={instagram} className="img-fluid" alt="img" />
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="https://www.facebook.com/wowmixtr/"
                      target="_blank"
                    >
                      <img src={facebook} className="img-fluid" alt="img" />
                    </a>
                  </li>
                </ul>
                <h5 className="text-warning mt-4"> Contact Us </h5>
                <ul className="list-unstyled">
                  <li>
                    <span className="text-white">
                      <FontAwesomeIcon icon={faMapMarkedAlt}/>
                      170 A, Istiklal CD, Beyoglu / istanbul ( In front of
                      RichMond Hotel)
                    </span>
                  </li>
                  <li>
                    <a href="tel:+902122939119">
                      {" "}
                      <FontAwesomeIcon
                          icon={faPhoneSquare}
                      /> +902122939119{" "}
                    </a>
                  </li>
                  <li>
                    <a href="mailto:info@wowmixtr.com">
                      <FaMailBulk/> info@wowmixtr.com
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-6 mt-md-0 mt-4 border-right center-footer">
              <img src={logo} className="img-fluid d-block mx-auto" alt="" />
              <p className="text-muted text-center mt-4">
                {" "}
                Spreading “Wow Mix” brand in all world countries make our customers happy by offering a healthy, fresh and useful product for adults and children
              </p>
            </div>
            <div className="col-md-3 ">
              <div className="social text-center text-md-left mt-md-0 mt-4 d-none d-sm-block ">
                <h5 className="text-warning"> Follow us  </h5>
                <ul className="list-inline mt-4">
                  <li className="list-inline-item">
                    <a
                      href="https://www.instagram.com/wowmixtr/"
                      target="_blank"
                    >
                      <img src={instagram} className="img-fluid" alt="img" />
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="https://www.facebook.com/wowmixtr/"
                      target="_blank"
                    >
                      <img src={facebook} className="img-fluid" alt="img" />
                    </a>
                  </li>
                </ul>
                <h5 className="text-warning mt-4"> Contact Us </h5>
                <ul className="list-unstyled">
                  <li>
                    <span className="text-white">
                      <FontAwesomeIcon icon={faMapMarkedAlt} />
                      {" "} 170 A, Istiklal CD, Beyoglu / istanbul ( In front of
                      RichMond Hotel)
                    </span>
                  </li>
                  <li>
                    <a href="tel:+902122939119">
                      {" "}
                      <FontAwesomeIcon
                        icon={faPhoneSquare}
                      /> +902122939119{" "}
                    </a>
                  </li>
                  <li>
                    <a href="mailto:info@wowmixtr.com">
                      <FaMailBulk/> info@wowmixtr.com
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row py-3">
            <div className="col-6">
              <span className="text-muted mt-3 ">
                {" "}
                Copyright all rights reserved{" "}
                <a
                  href="https://majallab.com"
                  target="_blank"
                  className="text-white"
                  rel="noopener noreferrer"
                >
                  Majal{" "}
                </a>{" "}
              </span>
            </div>
            <div className="col-6">
              <a href="#" target="_blank" rel="noopener noreferrer">
                <img
                  src={logo_majal}
                  className="img-fluid d-block ml-auto"
                  alt="Majal Lab"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
