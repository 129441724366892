import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import ReduxThunk from 'redux-thunk';
import { createStore, applyMiddleware} from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducers from './store/reducers/rootReducers'

import * as serviceWorker from './serviceWorker';


const store = createStore(rootReducers, composeWithDevTools(
    applyMiddleware(ReduxThunk),
    // other store enhancers if any
  ))


ReactDOM.render(<Provider store={store}>   <App /> </Provider> , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
