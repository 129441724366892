import React, { Component } from "react";
import "./gallery.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import Line from "../../../img/Line.svg";
import { imageUrl } from '../../../urls/urls'
import bg_rounded from "../../../img/bg-rounded.svg";
import placeholder from "../../../img/placeholder.jpg";
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { galleryApi } from '../../../store/actions/gallery'

class Gallery extends Component {

  componentDidMount = () => {
    this.props.galleryApi()
  }

  render() {

    const { gallery_info } =this.props

    return (
      <div className="gallery pt-md-5">
        <div className="container mt-md-3 pt-md-5 ">
          <div className="row">
          <div className="col-md-5 offset-md-2 mt-3 d-block d-md-none">
              <h1 className="h3 mb-3 pt-md-5 mt-md-5">
                <img src={Line} className="img-fluid mr-3" alt="img" />
                Photo Gallery{" "}
              </h1>
              <p className="text-muted">
                You can choose between 70 products, all fresh with great tasting and at a Suitable price
              </p>
              <div className="row">
                <div className="col-5">
                  <Link href='https://www.instagram.com/wowmixtr/' className="text-left btn-warning btn-block" >
                    More{" "}
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      //   pull="right"
                      className="mt-1 "
                    />
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-md-5 ">
              <div className="photos mt-md-0 mt-4">
                {/* <img src={Photo_Gallery} className="img-fluid tall" alt="img" /> */}
                <div className="row">
                {gallery_info
                  ? gallery_info.slice(0, 4).map( (g, index) => {
                      return (
                          <div className="col-6">
                            <div className="photo-item  mb-3">
                              { gallery_info ?  <img src={imageUrl+g.image} className="img-fluid tall" alt="img" /> :
                                <img src={placeholder} className="img-fluid tall" alt="img" />
                               }
                            </div>
                          </div>
                      );
                    })
                  : null}
                        </div>
              
              </div>
            </div>
            <div className="col-md-5 offset-md-2 mt-3 d-none d-md-block">
              <h1 className="h3 mb-3 pt-md-5 mt-md-5">
                <img src={Line} className="img-fluid mr-3" alt="img" />
                Photo Gallery{" "}
              </h1>
              <p className="text-muted">
              You can choose between 70 products, all fresh with great tasting and at a Suitable price
              </p>
              <div className="row">
                <div className="col-5">
                <a href='https://www.instagram.com/wowmixtr/' target="_balnk" className="text-left btn-warning btn-block" >
                    More{" "}
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      className="mt-1 "
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-rounded">
            <img src={bg_rounded} className="img-fluid" alt="img" />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    gallery_info : state.gallery
  }
}



export default connect(mapStateToProps, {galleryApi})(Gallery) 
