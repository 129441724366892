import React, { Component } from "react";
import "./header.scss";
import { imageUrl } from "../../../urls/urls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import { slider } from "../../../store/actions/slider";
import Slider from "./Slider";
// import { Link } from 'react-router-dom'
import { HashLink as Link } from 'react-router-hash-link';

class Header extends Component {
  componentDidMount = () => {
    this.props.slider();
  };

  render() {
    const { sliderInfo } = this.props;

    return (
      <header className="header pt-5">
        <div className="container mt-md-5">
          <div className="row">
            <div className="col-md-4 pt-md-5 mt-md-4 ">
              <h1 className="h3 text-md-left text-center">
                Get the Happiness
              </h1>
              <p className="text-md-left text-center">
                The Happiness feeling comes when you taste frozen yogurt with different flavors
              </p>
              <div className="row">
              <div className="col-7 mx-auto mb-5 d-block d-sm-none">
                  <Link to="/contact-us#location">
                      <Button variant="dark" className="text-left" block>
                      Get Directions <FontAwesomeIcon icon={faArrowRight} className="mt-1 " />
                  </Button>
                  </Link>
              </div>
              <div className="col-7 mb-5 d-none d-sm-block">
                  <Link to="/contact-us#location">
                      <Button variant="dark" className="text-left" block>
                      Get Directions <FontAwesomeIcon icon={faArrowRight} className="mt-1 " />
                  </Button>
                  </Link>
              </div>
            </div>
            </div>
            <div className="col-md-7 offset-md-1">
              <Slider />
            </div>
          </div>

        </div>
      </header>
    );
  }
}

const mapStateToProp = state => {
  return {
    sliderInfo: state.sliderInfo
  };
};

export default connect(mapStateToProp, { slider })(Header);
