import React, { Component } from "react";
import "./about.scss";
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import poster from "../../../img/wowmix (5).jpg";
import play_video from "../../../img/play_video.svg";
import Line from "../../../img/Line.svg";
import { imageUrl } from '../.../../../../urls/urls'
import  { connect } from 'react-redux'
import { video } from '../../../store/actions/video'

 
 class About extends Component {

  componentDidMount = () => {
    this.props.video()
  }

  render() {
    var { videoData } = this.props;
    videoData = videoData[0]
    return (
      <div className="about" ref={this.myRef}>
        <div className="container mt-md-3 pt-md-5 ">
          <div className="row">
            <div className="col-md-4 ">
              <h1 className="h3 mb-3">
                <img src={Line} className="img-fluid mr-3" alt="img" />
                About Us{" "}
              </h1>
              <p className="text-muted">
              We make our customers happy by offering a healthy, fresh and useful product for adults and children
              </p>
              <div className="row">
                <div className="col-6">
                  <Link to="/about-us" className="mr-2 btn btn-warning text-left btn-block">
                    More
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      className="mt-1 "
                    />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-7 offset-md-1">
              <div className="bg-video bg-warning">
                <img src={poster} className="img-fluid d-block" alt="img" />
                <img src={play_video} data-toggle="modal" data-target="#exampleModalCenter" className="img-fluid d-block" alt="" />
              </div>
            </div>
          </div>

          <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog  " role="document">
              <div class="modal-content">
              <div class="modal-header px-4">
                    <h5 class="modal-title" id="exampleModalLabel">Video</h5>
                    <button type="button" class="close mt-1" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
              </div>
              <div class="modal-body">
                <video  controls className="embed-responsive embed-responsive-16by9 h-25">
                    <source src={videoData ? imageUrl+videoData.image : null} type="video/mp4"/>
                  {  }
                  Your browser does not support HTML5 video.
                </video>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    videoData : state.video
  }
}

export default connect( mapStateToProps, { video } )(About)