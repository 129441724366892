import React, { Component, Fragment } from "react";
import { Navbar, Nav } from "react-bootstrap";
import logo from "../../../img/logo_wowmax.svg";
import menu_symbol from "../../../img/menu_symbol.svg";
import bg_path_right from '../../../img/bg-path-right.svg'
import { Link } from 'react-router-dom';
import "./navbar.scss";

export default class NavbarWowmix extends Component {

  state = {
    toggleMenuBar: null
  };

  toggleMenu = () => {
    this.setState({ toggleMenuBar: !this.state.toggleMenuBar });
  };

  render() {
    return (
      <Fragment >

        <Navbar bg="dark" variant="dark" expand="lg" className="NavbarWowmix py-1 d-none d-sm-block" >
          <div className="container">
            <Link to="/" className="navbar-brand  " >
              {/* Wowmix */}
              <img src={logo} className="img-fluid logo-style" alt="" />
            </Link>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto">
                <Link to="/" className="mx-2" >Home</Link>
                <Link to="/about-us" className="mx-2">About Us</Link>
                <Link to="/category" className="mx-2">Products</Link>
                {/* <Link to="/gallery" className="mx-2">Gallery</Link> */}
                {/*<Link to="/OwnAFranchise" className="mx-2">Own A Franchise</Link>*/}
                <Link to="/contact-us" className="mx-2">Contact</Link>
                <Link to="/join-us" className="mx-2">Join Us</Link>
              </Nav>

            </Navbar.Collapse>
          </div>
        </Navbar>
        <div className="parent-menu-mobile bg-dark px-3 py-2 p-md-3 d-block d-sm-none">
          <div className="container">
            <h6 className="my-0 py-0">
              {" "}
              <img
                src={logo}
                className="img-fluid logo-icon-menu-mobile"
                alt="img"
                onClick={this.toggleMenu}
              />
              <img
                src={menu_symbol}
                className="img-fluid float-right menu-icon mt-3"
                alt="img"
                onClick={this.toggleMenu}
              />
            </h6>
          </div>
        </div>
        {this.state.toggleMenuBar ? (
          <div
            className="menu-mobile-screen p-4 "
          >
            <div className="border-bottom pb-4 header-menu ">
              <h6 className="d-inline">
                {" "}
                Menu
                <span
                  className="d-inline-block float-right close-menu "
                  onClick={this.toggleMenu}
                >
                  {" "}
                  X{" "}
                </span>
              </h6>
              <span onClick={this.toggleMenu} className="d-block float-left">
                {" "}
                <i className="fas fa-times"></i>{" "}
              </span>
            </div>
            <div className="mt-3 ">
              <ul
                className="list-unstyled mt-5"
              >
                <li>
                  <Link onClick={this.toggleMenu} to="/">Home</Link>
                </li>
                <li>
                  <Link onClick={this.toggleMenu} to="/about-us"> About Us </Link>
                </li>
                <li>
                  <Link onClick={this.toggleMenu} to="/category"> Products </Link>
                </li>
                {/*<li>*/}
                {/*  <Link onClick={this.toggleMenu} to="/OwnAFranchise"> Own A Franchise </Link>*/}
                {/*</li>*/}

                <li>
                  <Link onClick={this.toggleMenu} to="/contact-us"> Contact </Link>
                </li>

                <li>
                  <Link onClick={this.toggleMenu} to="/join-us">Join Us</Link>
                </li>
              </ul>
            </div>
            <img src={bg_path_right} className="img-fluid bg-path-right" alt="img" />
          </div>
        ) : null}
      </Fragment>

    );
  }
}
