import React from 'react'
import { FaWhatsapp, FaTripadvisor, FaMailBulk } from 'react-icons/fa';
import './owanAFreanchise.scss';

export const Email_Whatsapp = () => {
    return (
        <>
            <ul class="list-inline">
                <li class="list-inline-item" style={{float: "left"}}><a href="https://wa.me/902122939119" target="_blank" className="btn-warning email-whatsapp-fontsize"><FaWhatsapp /> Chat</a></li>
                <li class="list-inline-item" style={{float: "right"}}><a href="mailto:info@wowmixtr.com" className="btn-warning email-whatsapp-fontsize"><FaMailBulk /> Email Us</a></li>
            </ul>
        </>
    )
}

export default Email_Whatsapp;
