import React, { Component, Fragment } from "react";
import ProductSummiry from "./ProductSummiry";
import { connect } from "react-redux";

class ProductsCategry extends Component {
  render() {
    const { category_by_id } = this.props;
    return (
      <Fragment>
        {category_by_id
          ? category_by_id.map(products => {
              return <ProductSummiry key={products.id} products={products} />;
            })
          : null}
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    category_by_id: state.category_by_id
  };
};

export default connect(mapStateToProps)(ProductsCategry);
