import React, { Component } from 'react'
import  './homepage.scss'
import Header from './header/Header'
import Department from './department/Department'
import Gallery from './gallery/Gallery'
import About from './about/About'
import Franchise from './franchise/Franchise'
import { department } from "../../store/actions/department";
import { connect } from 'react-redux'

 class HomePage extends Component {

    componentDidMount = () => {
        this.props.department();
      };
    

    render() {
        return (
            <div>
                <Header/>
                <Department/>
                <Gallery/>
                <About/>
                <Franchise/>
            </div>
        )
    }
}



export default connect(null , {department} )(HomePage) 
