import React, { Component } from "react";
import "./aboutUs..scss";
import twitter from "../../img/twitter.png";
import instagram from "../../img/links/instagram.png";
import facebook from "../../img/links/facebook.png";
import youtube from "../../img/links/youtube.png";
import about_us from "../../img/about_us.png";
import art_path_left from "../../img/art_path_left.svg";
import mission from "../../img/mission.svg";
import vision from "../../img/vision.svg";
import diamond from "../../img/diamond.svg";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

export default class AboutUs extends Component {
  render() {
    return (
      <div className="AboutUs py-5 my-md-5 ">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <h1 className="h3 border-heading d-inline-block"> About Us </h1>
              <small className="text-muted d-block">
                {" "}
                Wow Mix is ​​a Turkish brand
Our head office is located in Istiklal Street in Istanbul, our main products (frozen yogurt, Waffle, Coffees and Ice cream)
                 {" "}
                 {/* An introductory profile of the companyغ */}
              </small>
            </div>
            <div className="col-md-8 d-none d-sm-block">
              <ul className="list-inline mt-4 float-right">
                <li className="list-inline-item">
                  <a href="https://www.instagram.com/wowmixtr/" target="_blank">
                    <img src={instagram} className="img-fluid" alt="img" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="https://www.facebook.com/wowmixtr/" target="_blank">
                    <img src={facebook} className="img-fluid" alt="img" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="desc-about mt-5 ">
            <div className="row">
              <div className="col-md-5">
                <img src={diamond} className="img-fluid mr-3" alt="img" />
                <h5 className=" border-heading d-inline-block"> Our Values </h5>
                <ul className="list-unstyled mt-4">
                  <li> <FontAwesomeIcon
                      icon={faCircle}
                    /> Provide fresh healthy product with the best price & highest quality. </li>
                  <li> <FontAwesomeIcon
                      icon={faCircle}
                    /> Avoid any kind of expenses that can increase our products & services prices.</li>
                  <li> <FontAwesomeIcon
                      icon={faCircle}
                    /> Determine the count of products to only “70 products” as maximum, which allows to conduct quality checks efficiently and provide customers with products at suitable prices.</li>
                  <li> <FontAwesomeIcon
                      icon={faCircle}
                    /> Our customers pay only the product and service price, not the brand price.</li>
                  <li> <FontAwesomeIcon
                      icon={faCircle}
                    /> We take the self-service principle to our customers to avoid the increase in prices of product and service to feel our customers fully comfortable within the place.</li>
                  <li> <FontAwesomeIcon
                      icon={faCircle}
                    /> Saving the environment through recycling the paper materials.</li>
                </ul>
              </div>
              <div className="col-md-5 offset-md-2">
                <img src={about_us} className="img-fluid mt-3" alt="img" />
              </div>
            </div>
          </div>
          <div className="value mt-5 pt-md-5">
            <div className="row">
              <div className="col-md-5">
                <img src={vision} className="img-fluid mr-3" alt="img" />
                <h5 className=" border-heading d-inline-block"> vision </h5>
                <p className="mt-4">
                  Spread “Wow Mix” brand in all world countries, and make our
                  customers happy by offering a healthy, fresh and useful
                  product for adults and children
                </p>
              </div>
              <div className="col-md-5 offset-md-2">
                <img src={mission} className="img-fluid mr-3" alt="img" />
                <h5 className=" border-heading d-inline-block"> Mission </h5>
                <p className="mt-4">
                  Assisting the community by providing the best product in a
                  healthy way, fresh and suitable price for all society
                  segments.
                </p>
              </div>
            </div>
          </div>
          <div className="art_path_left">
            <img src={art_path_left} className="img-fluid" alt="img" />
          </div>
        </div>
      </div>
    );
  }
}
